import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/actu": [~6],
		"/amigo/calculateur.html": [~7],
		"/amigo/resultats": [~8],
		"/amigo/resultats/resultats-amigo-tirages-du-[date].html": [~9],
		"/authors": [~10],
		"/authors/[id]": [~11],
		"/euromillions": [~12],
		"/euromillions/calculateur.html": [~13],
		"/euromillions/resultats": [~14],
		"/euromillions/resultats/resultats-euromillions-tirage-du-[date].html": [~15],
		"/keno": [~16],
		"/keno/calculateur.html": [~17],
		"/keno/resultats": [~18],
		"/keno/resultats/resultats-keno-tirages-du-[date].html": [~19],
		"/loto": [~20],
		"/loto/calculateur.html": [~21],
		"/loto/resultats": [~22],
		"/loto/resultats/resultats-loto-tirage-du-[date].html": [~23],
		"/recherche": [~24],
		"/rss.html": [~25],
		"/[slug=singlepage].html": [~5],
		"/[...slug=post].html": [~4],
		"/[...path]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';